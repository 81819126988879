import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFrameComponent } from './login-frame/login-frame.component';

const routes: Routes = [
  { path: '', redirectTo: 'inventory', pathMatch: 'full' },
  {
    path: 'inventory',
    loadChildren:
      './livemarket/modules/inventory/inventory.module#InventoryModule',
  },
  {
    path: 'valuations',
    loadChildren:
      './livemarket/modules/appraisals/appraisals.module#AppraisalsModule',
  },
  {
    path: 'appraisals',
    loadChildren:
      './livemarket/modules/appraisals/appraisals.module#AppraisalsModule',
  },
  {
    path: 'stocking',
    loadChildren:
      './livemarket/modules/stocking/stocking.module#StockingModule',
  },
  {
    path: 'livebook',
    loadChildren:
      './livemarket/modules/livebook/livebook.module#LivebookModule',
  },
  {
    path: 'reporting',
    loadChildren:
      './livemarket/modules/reporting/reporting.module#ReportingModule',
  },
  {
    path: 'settings',
    loadChildren:
      './livemarket/modules/settings/settings.module#SettingsModule',
  },
  { path: 'login-frame', component: LoginFrameComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
