import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import { environment } from '@environments/environment';
import { CookieService } from 'ngx-cookie';
import { LocalStorageService, WINDOW } from '@seamless/core';
import { Router } from '@angular/router';

/**
 * A component with minumum html for fast loading, and is in charge of
 * - Sending "Ready" Signal to advise LiveMarket Admin that it's ready to accept communication
 * - Handle impersonation request from LiveMarket Admin, replacing the current "token" with the payload's
 */
@Component({
  selector: 'lm-login-frame',
  templateUrl: './login-frame.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFrameComponent implements OnInit {
  constructor(
    private readonly cookieService: CookieService,
    private readonly localStorageService: LocalStorageService,
    private router: Router,
    @Inject(WINDOW) private window: Window
  ) {}

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.origin !== environment.liveMarketAdminUrl || !event.data?.token) {
      return;
    }
    this.localStorageService.set('token', event.data.token);
    this.cookieService.put('token', event.data.token);

    //New cookies will not be not applied until page reload
    const rootUrl = this.window.location.href.replace('login-frame', '');
    this.window.location.replace(rootUrl);
  }

  ngOnInit(): void {
    //Notify LMA that it's ready
    if (this.window.opener) {
      this.window.opener.postMessage('ready', environment.liveMarketAdminUrl);
    } else {
      this.router.navigate(['/'], { replaceUrl: true });
    }
  }
}
