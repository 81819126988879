<div class="join-beta-card-container">
    <div class="join-beta-header-container">
        <div>
            <h1 class="join-beta-header-container__header">
                <span [ngStyle]="{'font-size': '20px'}">Coming Soon!</span>
                <br>
                <span>The improved Inventory page has lots of new features</span>
            </h1>
        </div>
        <div class="join-beta-header-container_close-button-container">
            <span (click)="close()" aria-hidden="true" type="button">
                <img src="assets/icons/close.svg" class="join-beta-header-container_close-button-container_button" />
            </span>
        </div>
    </div>
    <div class="insights-report-image">
        <img src="assets/images/beta-banner-2.png" />
    </div>
    <div class="join-beta-content">
        <span>
            <b>New features include</b>
        </span>
        <ul>
            <li>View all your listings at both Group and Account levels</li>
            <li>Easily track price changes within the inventory list view</li>
            <li>Quickly update vehicle prices directly from the listing page</li>
            <li>Get recommendations for Promote products</li>
            <li>Add notes to listings for personal or team reference</li>
        </ul>        
    </div>   
</div>