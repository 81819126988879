import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService, NotificationService, AuthenticationService, IBaseJWT, StorageKeyService } from '@seamless/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import { environment } from '@environments/environment';
import { RegionModel } from '@models/spec-data/region.model';
import { SellerSettingsDTO } from '@seamless/core/lib/dto/seller/seller-settings.dto';

@Injectable()
export class SettingsService {
    settings: SellerSettingsDTO;
    
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private notificationService: NotificationService,
        private authenticationService: AuthenticationService,
        private storageKeyService: StorageKeyService
    ) {}

    getRegions() {
        const apiPath = environment.seamless.api.experience + 'api/' + 'RefData/Regions?pageSize=1000';

        return this.http.get<{ data: RegionModel[] }>(apiPath).pipe(catchError(error => this.handleError(error)));
    }

    updateUserSettings(userReceivePricingAlerts: boolean, userReceiveDealerPrincipalAlerts: boolean, userReceiveInsightReports: boolean) {
        const apiPath =
            environment.seamless.api.experience +
            'api/' +
            `${environment.seamless.tenant}/` +
            'user/' +
            this.authenticationService.decodeToken<IBaseJWT>().UserIdentifier +
            '/settings';

        return this.http
            .put(apiPath, {
                membershipId: this.authenticationService.decodeToken<IBaseJWT>().UserIdentifier,
                tenant: environment.seamless.tenant,
                optIn_PricingAlerts: userReceivePricingAlerts,
                optIn_DealerPrincipalAlerts: userReceiveDealerPrincipalAlerts,
                optIn_InsightReports: userReceiveInsightReports
            })
            .pipe(catchError(error => this.handleError(error)));
    }


    public getSellerSettings(): Observable<SellerSettingsDTO> {
        const apiPath = `${environment.seamlessPublic.experienceApi}${environment.seamlessPublic.tenant}/${environment.seamlessPublic.version}/sellers/settings`;
        return this.http.get<SellerSettingsDTO>(apiPath).pipe(
          map((res) => {         
            this.settings = res;
            return res;
          }), catchError((x, y) => {
            return of(null);
          })
        );
      }

    private handleError(error: any): Observable<any> {
        // this.notificationService.display({
        //     title: 'Error',
        //     message: error.message,
        //     type: 'fail',
        //     isRead: false
        // });
        return throwError(error);
    }
}
