import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    AuthenticationService,
    IBaseJWT,
    StorageKeyService,
    LocalStorageService
} from '@seamless/core';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private storageKeyService: StorageKeyService,
    private localStorageService: LocalStorageService,

  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get sellerGuid and membershipId from your storage or authentication service
        const token = this.authenticationService.decodeToken<IBaseJWT>();
        const sellerGuid = this.localStorageService?.get(this.storageKeyService.SELLER_GUID) ?? 'sellerGuidNotAvailable';
        const membershipId = token?.UserIdentifier ?? 'tokenNotAvailable';
        // Clone the request and add headers
        const modifiedRequest = request.clone({
        
          setHeaders: {
            sellerGuid: sellerGuid,
            membershipId: membershipId
          }
        });

        // Pass the modified request to the next interceptor or the HttpClient
        return next.handle(modifiedRequest);
  }
}