import { Component, OnInit, Input } from '@angular/core';
import { JoinBetaModalComponent } from '@shared/components/join-beta-modal/join-beta-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'lm-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {

  @Input() advertimentImg: string;
  @Input() advertimentImgAltText: string;
  @Input() href: string;
  bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService) { 

  }

  ngOnInit() {
  }

  openBetaTrialModal() {
    this.bsModalRef = this.modalService.show(JoinBetaModalComponent);
}

}
