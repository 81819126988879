<seamless-app-shell>
  <seamless-sidebar-menu-custom>
    <accordion>
      <accordion-group [isOpen]="true">
        <div accordion-heading>
          <i class="icon-livemarket"></i>
          LiveMarket
        </div>

        <a *ngIf="!showNewLivemarketInventory" role="button" routerLink="/inventory" routerLinkActive="active">
          Inventory
        </a>

        <a *ngIf="showNewLivemarketInventory" role="button" [href]="livemarketInventoryListingsUrl">
          Inventory <span class="new-label">Beta</span>
        </a>

        <a role="button" [href]="sourcingValuationsUrl">
          Valuations <span class="new-label">New</span>
        </a>

        <a role="button" routerLink="/stocking" routerLinkActive="active">
          Stocking
        </a>

        <a role="button" routerLink="/livebook" routerLinkActive="active">
          Livebook
        </a>

        <a role="button" routerLink="/reporting" routerLinkActive="active">
          Reports
        </a>

        <a role="button" routerLink="/settings" routerLinkActive="active">
          Settings
        </a>
      </accordion-group>
    </accordion>
    <lm-advertisement advertimentImg="assets/images/beta-banner-1.png"></lm-advertisement>
  </seamless-sidebar-menu-custom>
</seamless-app-shell>
