import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'lm-join-beta-modal',
  templateUrl: './join-beta-modal.component.html',
  styleUrls: ['./join-beta-modal.component.scss']
})
export class JoinBetaModalComponent {

  constructor(private ref: BsModalRef)
  {}
  close(){
    this.ref.hide();
  }
}
