import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { SeamlessLivemarketModule } from '@seamless/livemarket';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';
import { AdvertisementComponent } from './adverts/advertisement/advertisement.component';
import { LoginFrameComponent } from './login-frame/login-frame.component';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { RequestInterceptor } from '@shared/Interceptors/Request-Interceptor';
import { SettingsService } from '@shared/services/settings/settings.service';

@Injectable()
export class NewRelicErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    console.error(error.originalError || error);
    throw error;
  }
}

@NgModule({
  declarations: [AppComponent, AdvertisementComponent, LoginFrameComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    SeamlessLivemarketModule.forRoot(environment),
    LayoutModule,
  ],
  providers: [{ provide: ErrorHandler, useClass: NewRelicErrorHandler }
    ,{ provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true
  }, SettingsService
],
  bootstrap: [AppComponent],
})
export class AppModule {}
